import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isCollapse: false,
    pc: true,
  },
  getters: {
  },
  //类似service
  mutations: {
    ISCOLLAPSE(state,value){
      state.isCollapse = value
    },
    PC(state,value){
      state.pc = value
    }
  },
  //类似controller
  actions: {
    isCollapse(minStore,value){
      minStore.commit("ISCOLLAPSE",value)
    },
    pc(minStore,value){
      minStore.commit("PC",value)
    }
  },
  modules: {
  }
})
