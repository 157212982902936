import axios from "axios";
import router from "@/router/index"

//创建请求配置基础信息
const instance = axios.create({
    baseURL: "https://xcx.shangweikeji.cn",
    // baseURL: "http://localhost:51000",
    timeout: 5000,

})
//请求拦截器
instance.interceptors.request.use((config) => {
    //token
    if (config.url === "/api/login1") {
        return config
    }
    const token = localStorage.getItem("token")
    if (token === null || token === '') {
        router.push('/login')
        return config
    }
    config.headers.token = token
    return config
})
//响应拦截器
instance.interceptors.response.use((data) => {
    console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq")
    console.log(data)
    return data.data
}, (error) => {
    if (error.response) {
        switch (error.response.status) {
            case 403:
                console.log("我是403",error)
                router.push('/')
                return {"code":-1,"msg":"登录失效，请重新登陆"};
            default:
                console.log("错了 但我不知道为啥",error)
        }
        return {"code":-1,"msg":error.message};
    }
})
export function get(url, params) {
    return instance.get(url, {
        params
    });
}

export function post(url, data) {
    return instance.post(url, data)
}