<template>
  <div class="login">
    <div class="left"></div>

    <div class="centre">
      <div class="top">后台管理</div>
      <div class="middle">
        <div class="title">
          用户登录
        </div>

        <div class="username">
          <el-input v-model="username" placeholder="请输入账号" prefix-icon="el-icon-s-custom
" class="custom-icon-color"></el-input>
        </div>
        <div class="password">
          <el-input placeholder="请输入密码" v-model="password" show-password prefix-icon="el-icon-lock"
            class="custom-icon-color"></el-input>
        </div>

        <el-button type="primary" @click="login">登录</el-button>
      </div>
      <div class="bottom"></div>
    </div>

    <div class="right"></div>
  </div>

</template>

<script>
import { login } from "@/utils/api/common"

export default {
  data() {
    return {
      username: '',
      password: '',
    }
  },
  methods: {
    //登录接口
    login() {
      if (this.username === '' || this.password === '') {
        this.$message({
          message: '账号或密码不能为空',
          type: 'warning'
        });
        return
      }
      login({
        username: this.username,
        password: this.password
      }).then( data => {
        if(data.code === 0){
          localStorage.setItem("token",data.data.token)
          this.$message({
            message: data.msg,
            type: 'success'
          })
          this.$router.push('/home')
        }else{
          this.$message.error(data.msg);
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
input {
  border: none;
  outline: none;
  /* 可选，移除焦点时的轮廓 */
}

.login {
  color: white;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/img/login/BG.png");
  display: flex;

  .left {
    flex: 3;

    @media (max-width: 600px) {
      flex: 0;
    }
  }

  .centre {
    flex: 4;

    .top {
      height: 10vh;
      margin-top: 5vh;
      background-image: url("../../assets/img/login/login_title@2x.png");
      background-repeat: no-repeat;
      background-size: 80% 50%;
      background-position: center;
      text-align: center;
      line-height: 10vh;
      font-weight: bold;
      font-size: 30px;

      @media (max-width: 600px) {
        margin-top: 15vh;
      }
    }

    .middle {
      width: 90%;
      margin: 0 5%;
      height: 55%;
      background-image: url("../../assets/img/login/login_input.png");
      background-repeat: no-repeat;
      background-size: 90% 90%;
      background-position: center;
      text-align: center;
      color: white;
      padding-top: 10vh;

      @media (max-width: 600px) {
        height: 265px;
      }

      .title {
        background-image: url("../../assets/img/login/login_user_title@2x.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50% 50%;

        @media (max-width: 600px) {
          font-size: 14px;
        }

        font-size: 22px;
      }


      .el-input {
        width: 90%;
        height: 50px;
        line-height: 50px;

        /deep/ .el-input__inner {
          background-color: transparent;
          color: white;
          border: none !important;
          /* 使用 !important 来确保覆盖默认样式 */
          box-shadow: none !important;
          /* 移除阴影 */
        }

        /deep/.el-input__icon {
          color: #fff !important;
        }

      }

      .username {
        width: 70%;
        margin: 5% 15%;
        background-image: url("../../assets/img/login/login_user_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90% 100%;
      }

      .password {
        width: 70%;
        margin: 5% 15%;
        background-image: url("../../assets/img/login/login_user_bg.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90% 100%;
      }

      .el-button {
        width: 60%;
        background-color: #0B3567;
        margin-top: 10px;
      }
    }

    .bottom {
      height: 10vh;
    }
  }

  .right {
    flex: 3;

    @media (max-width: 600px) {
      flex: 0;
    }

  }
}
</style>