import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/Index.vue'
import Login from '@/views/login/Index.vue'
import Home from '@/views/home/Home.vue'

const Dev = ()=>import('@/views/dev/Dev.vue')
const Generator = ()=>import('@/views/dev/generator/Generator.vue')
const Interface = ()=>import('@/views/dev/interface/Interface.vue')
const Server = ()=>import('@/views/server/Server.vue')
const ServerUser = ()=>import('@/views/server/user/User.vue')
const UserAdd = ()=>import('@/views/server/user/UserAdd.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    component: Layout,
    children:[
      {
        path: '/home',
        name: 'home',
        component: Home
      },
      {
        path: '/dev',//开发工具
        name: 'dev',
        component: Dev,
        children:[
          {
            path: 'generator',//代码生成
            name: 'generator',
            component: Generator
          },{
            path: 'interface',//接口测试
            name: 'interface',
            component: Interface
          }

        ]
      },
      {
        path: '/server',//站点管理
        name: 'server',
        component: Server,
        children:[
          {
            path: 'user',//用户管理
            name: 'user',
            component: ServerUser
          },{
            path: 'user-add',
            name: 'userAdd',
            component: UserAdd
          }
        ]
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
