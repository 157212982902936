<template>
  <div :class="!isCollapse ? 'menu' : 'menu-hidden'">
    <el-menu router :default-active="$route.path" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
      text-color="#fff" active-text-color="#ffd04b" :collapse="isCollapse" background-color="transparent">
      <el-menu-item index="/home">
        <i class="el-icon-menu"></i>
        <span slot="title">首页</span>
      </el-menu-item>


      <el-submenu index="/server">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>站点管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/server/user">站点用户</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-submenu index="/dev">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>开发工具</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/dev/generator">代码生成</el-menu-item>
          <el-menu-item index="/dev/interface">接口测试</el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-menu-item index="2">
        <i class="el-icon-menu"></i>
        <span slot="title">导航二</span>
      </el-menu-item>
      <el-menu-item index="3" disabled>
        <i class="el-icon-document"></i>
        <span slot="title">导航三</span>
      </el-menu-item>
      <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title">导航四</span>
      </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
  mounted() {
    this.$bus.$on("updateCollapse", (data) => {
      console.log("我是menu，收到数据", data[0]);
      this.isCollapse = data[0];
    });
  },
};
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

.el-menu>.el-menu-item:first-child {
  margin-top: 15px !important;
}

.el-menu>.el-menu-item {
  margin: 0 20px !important;
  padding: 0 !important;

  @media (max-width: 600px) {
    margin: 0 !important;
  }
}

.el-menu {
  border-right: 0;
  background-color: transparent !important;

  .is-active {
    background: transparent !important;
  }

  .el-menu-item:hover {
    background: transparent !important;
  }

  .el-submenu {
    margin: 0 20px !important;

    @media (max-width: 600px) {
      margin: 0 !important;
    }

    .el-menu-item-group {
      background-color: #010B23;

      .el-menu-item {
        padding: 0 0 0 60px;
      }
    }
  }

}

.el-submenu .el-menu-item {
  min-width: 0px !important;
}

.menu {
  width: 220px;
  text-align: center;
  // background-position: center;
  background-image: url("../../../assets/img/navsliderbg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.menu-hidden {
  margin-right: 20px;

  @media (max-width: 600px) {
    margin-right: 0px;
  }
}

.el-menu-vertical-demo[data-v-c847f446]:not(.el-menu--collapse) {
  width: 200px;
}

.el-submenu .el-menu-item {
  margin: 0 5px;
  .el-menu-item-group{
    background-color: transparent !important;
  }
}

.el-submenu /deep/ .el-submenu__title:hover {
  background-color: transparent !important;
}
.el-menu--collapse{
  @media (max-width: 600px) {
    width: 40px;
  }
}
</style>