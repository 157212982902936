<template>
  <div class="layout">
    <div class="header">
      <Header></Header>
    </div>
    <div class="body">
      <div class="menu">
        <Menu></Menu>
      </div>
      <div class="content">
        <Content></Content>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "./menu/Index.vue";
import Content from "./content/Index.vue";
import Header from "./header/Header.vue";

export default {
  components: {
    Menu,
    Content,
    Header,
  },
};
</script>

<style lang="less" scoped>
.layout {
    color: aliceblue;
    background-position: center;
    background-image: url("../../assets/img/bigbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    // display: flex;
  //   flex-direction: row;
  .header {
    display: block;
    height: 14vh;
    // flex-grow: 1;
  }
  .body {
    overflow: hidden;
    display: flex;
    .menu {
      // float: left;
      // width: 200px;
    //   background: #888;
      // position: fixed;
      // left: 0;
      // top: 100;
      // bottom: 0;
      height: 86vh;
      
    }
    .content {
      // float: right;
      // width: 800px;
      // flex: 1;
      // flex-grow: 1;
      // padding-left: 200px;
      // justify-content:center;
    }
  }
}
</style>