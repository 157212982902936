<template>
    <div>
        <!-- <Header></Header> -->
        <router-view></router-view>
    </div>
</template>

<script>
import Header from '../header/Header.vue'
export default {
    components:{
        Header
    }
}
</script>

<style>

</style>