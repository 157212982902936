<template>
    <div class="header">
        <div class="title">
            <div class="title2" v-if="screenSize === 'small'">
                后台
            </div>
            <div class="title1" v-else-if="screenSize === 'large'">
                ERP中继后台
            </div>
        </div>
        <div class="zhong" v-show="!isMobile">
            <div class="shou" v-show="!open"><i class="el-icon-s-fold" @click="sendCollapse(true)"></i></div>
            <div class="zhan" v-show="open"><i class="el-icon-s-unfold" @click="sendCollapse(false)"></i></div>
        </div>
        <div class="hou">
        </div>

    </div>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            isMobile: false,
            open: false,
            screenSize: 'small', // 默认值
            clicked: false,
            wait: 1
        }
    },
    methods: {
        checkUserAgent() {
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;
            this.isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
            //vuex
            this.$store.dispatch("pc",!this.isMobile)
        },
        sendCollapse(data) {
            //vuex
            this.$store.dispatch("isCollapse", data)
            //
            if (this.wait) {
                setTimeout(() => {
                    console.log("-----------------------------------------------------------------")
                    console.log("收到data：", data)
                    console.log("this.clicked：", this.clicked)
                    console.log("this.open：", this.open)
                    if (this.clicked || this.open === data) {
                        return
                    }
                    this.open = data
                    this.clicked = true
                    this.$bus.$emit("updateCollapse", [data, 130]);
                    console.log(this)
                    console.log("事件总线：", data)
                    setTimeout(() => {
                        this.clicked = false;
                    }, 500); // 设置 0.5 秒后可再次点击

                }, this.wait);
            }


        },
        updateScreenSize() {
            console.log("=", window.innerWidth)
            this.screenSize = window.innerWidth > 1380 ? 'large' : 'small';
            window.innerWidth < 750 ? this.sendCollapse(true) : null;
        },
    },
    mounted() {
        this.checkUserAgent();
        this.updateScreenSize();
        window.addEventListener('resize', this.updateScreenSize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateScreenSize);
    },
};
</script>

<style lang="less" scoped>
.header {
    color: aliceblue;
    //   background-position: center;
    background-image: url("../../../assets/img/topbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    //   height: 50px;
    //   line-height: 50px;
    display: flex !important;

    .title {
        position: relative;
        flex: 2;
        // text-align: center;
        // height: 10vh;
        // line-height: 10vh;
        font-size: 20px;
        font-weight: bold;
        min-width: 22%;

        .title1 {
            position: absolute;
            top: 20px;
            left: 90px;
        }

        .title2 {
            position: absolute;
            top: 15px;
            left: 60px;
            min-width: 50px;

            @media (max-width: 600px) {
                left: 75px;
            }

        }
    }

    .zhong {
        flex: 1;
    }

    .hou {
        flex: 10;
    }
    i{
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .header {
        // overflow-x: auto;
        /* 水平滚动条 */
    }
}
</style>